@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css";

@import url('https://fonts.googleapis.com/css2?family=Anek+Kannada:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '../components/multi-range-slider/multi-range-slider.css';

@layer base {
    body.dark {
        background-color: #202020;
        /*background-image:url(/images/stars.svg);*/
    }

    h4 {
        @apply font-bold text-lg;
    }

    input {
        @apply border border-gray-100 px-5 py-1 rounded-md w-full;
    }

    input,
    input.form-control {
        @apply shadow-[0_35px_60px_-15px_rgba(0,0,0,0)] border border-gray-300 dark:!border-darkBorder dark:bg-darkCover dark:text-white h-[40px];
    }

    h3 {
        @apply text-xl font-bold;
    }

    @font-face {
        font-family: "shoika-retinaregular";
        src: url("shoika-retina-webfont.woff2") format("woff2"),
        url("shoika-retina-webfont.woff") format("woff");
        font-weight: normal;
        font-style: normal;
    }
    * {
        font-family: "shoika-retinaregular";
    }

    .dark .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid #00ff9b;
    }

    .gradientText {
        @apply text-transparent bg-clip-text bg-gradient-to-r from-newtextGradientStart to-newtextGradientEnd;
    }

    .bg-gradient {
        @apply bg-gradient-to-r from-newtextGradientStart to-newtextGradientEnd;
    }

    .changeGradient {
        @apply from-newtextGradientEnd to-newtextGradientStart;
    }

    .dark input:checked + .slider {
        @apply bg-gradient-to-r from-newtextGradientStart to-newtextGradientEnd;
    }

    .dark input:focus + .slider {
        box-shadow: none;
    }

    .dark input:checked + .slider.round:before {
        background: black;
    }

    .dark .slider.round:before {
        @apply bg-gradient-to-r from-newtextGradientStart to-newtextGradientEnd;
    }

    .dark .react-toggle-track {
        background: transparent !important;
        border: 1px solid newtextGradientStart !important;
    }

    .dark .react-toggle-thumb {
        background: bg-gradient !important;
        border: 1px solid transparent !important;
    }

    .dark .react-toggle--checked .react-toggle-track {
        @apply !bg-gradient-to-r !from-newtextGradientStart !to-newtextGradientEnd;
        border: 1px solid #27ff97 !important;
    }

    .dark .react-toggle--checked .react-toggle-thumb {
        background: #000000 !important;
        border: 1px solid transparent !important;
    }

    .orange-outline-button {
        @apply !border !border-newOrange !text-newOrange hover:!text-black hover:!bg-newOrange !rounded-full;
    }

    .orange-button {
        @apply bg-newOrange text-black hover:bg-transparent hover:border hover:border-newOrange hover:text-newOrange;
    }

    .violet-outline-button {
        @apply !border !border-violet !text-violet hover:!text-black hover:!bg-violet !rounded-full;
    }

    .violet-button {
        @apply bg-violet text-black hover:bg-transparent hover:border hover:border-violet hover:text-violet;
    }

    .borderGradient {
        border: 1px solid transparent;
        border-image: linear-gradient(45deg, #00ff9b, #00b8b5);
        border-image-slice: 1;
    }

    .paginationDark {
        @apply rounded border !text-newOrange border-newOrange bg-transparent hover:bg-newOrange hover:!text-black;
    }

    /*.dark .react-confirm-alert-button-group > button{
      transition: all .5s;
    }*/
    .dark .react-confirm-alert-button-group > button:hover {
        @apply bg-gradient text-black;
    }

    .dark .suggestion-item:hover {
        @apply bg-gradient !text-black;
    }
}

@layer components {
    .button {
        @apply bg-black
        text-white
        px-3 py-2
        rounded-md
        hover:bg-gray-800
        dark:border dark:border-white dark:hover:bg-white dark:hover:text-black py-1;
    }
}
.p-3 {
    padding: 0.75rem !important;
}

.dark .overlay::before,
.dark .overlay::after {
    background: #161616;
    background: -moz-linear-gradient(90deg, #161616 0%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(90deg, #161616 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(90deg, #161616 0%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#161616", endColorstr="#161616", GradientType=1);

    content: "";
    height: 100%;
    position: absolute;
    width: var(--gradient-width);
    z-index: 2;
}

.Toastify__toast-container {
    z-index: 99999999999 !important;
}

.timeframe:focus-visible,
input:focus-visible,
.formOutline:focus-visible,
input:focus-visible {
    outline: 1px solid #1dabed !important;
    border-radius: 3px;
}

.slick-dots li button:before {
    font-size: 0px;
    border: 2px solid #f89412;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    background: transparent;
}

.slick-dots li.slick-active button:before {
    font-size: 0px;
    border: 2px solid #f89412;
    border-radius: 50%;
    background-color: #f89412;
}

.slick-prev:before {
    color: #f89412;
}

.slick-next:before {
    color: #f89412;
}

/* .slick-arrow.slick-prev,.slick-arrow.slick-next{
display:none!important;} */

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
    background: url("/images/range.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 1rem;
}

input[type="range"]::-moz-range-track {
    background: url("/images/range.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 1rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -8px; /* Centers thumb on the track */
    background-color: #000000;
    outline: none;
    height: 2rem;
    width: 0.4rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
    border: 1px solid #053a5f;
    outline: none;
    outline-offset: 0.125rem;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    outline: none;
    /*custom styles*/
    background-color: #27ff97;
    height: 2rem;
    width: 1rem;
}

input[type="range"]:focus::-moz-range-thumb {
    border: 1px solid #053a5f;
    outline: none;
    outline-offset: 0.125rem;
}

.dark .roundThumb input {
    border: none;
    padding: 0;
}

.roundThumb input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
}

.roundThumb input[type="range"]:focus {
    outline: none;
}

.roundThumb input[type="range"]::-webkit-slider-runnable-track {
    background: #303030;
    background-repeat: no-repeat;
    background-size: cover;
    height: 0.5rem;
}

.roundThumb input[type="range"]::-moz-range-track {
    background: #303030;
    background-repeat: no-repeat;
    background-size: cover;
    height: 0.5rem;
}

.roundThumb input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -4px; /* Centers thumb on the track */
    background-color: #00ff9b;
    outline: none;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
}

.roundThumb input[type="range"]:focus::-webkit-slider-thumb {
    border: 1px solid transparent;
    outline: none;
    outline-offset: 0.125rem;
}

.roundThumb input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 50%; /*Removes default border-radius that FF applies*/
    outline: none;
    background-color: #00ff9b;
    height: 1.2rem;
    width: 1.2rem;
}

.roundThumb input[type="range"]:focus::-moz-range-thumb {
    border: 1px solid transparent;
    outline: none;
    outline-offset: 0.125rem;
}

.react-flow__controls,
.socket {
    margin-bottom: 90px !important;
}

.selectFunds input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    border: 0px;
}

.selectFunds input[type="range"]:focus {
    outline: none;
}

.selectFunds input[type="range"]::-webkit-slider-runnable-track {
    background: #27ff97;
    background-repeat: no-repeat;
    background-size: cover;
    height: 1rem;
}

.selectFunds input[type="range"]::-moz-range-track {
    background: #27ff97;
    background-repeat: no-repeat;
    background-size: cover;
    height: 1rem;
}

.profile-image-upload-input {
    position: absolute;
    width: 230px;
    height: 230px;
    /*margin-left: -230px;*/
    opacity: 0;
    z-index: 99;
    cursor: pointer;
}

.range-wrapper input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    border: 0px;
}

.range-wrapper input[type="range"]:focus {
    outline: none;
}

.range-wrapper input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    border: 0px;
}

.range-wrapper input[type="range"]::-webkit-slider-runnable-track {
    background: #f6234e;
    background-repeat: no-repeat;
    background-size: cover;
    height: 1rem;
}

.range-wrapper input[type="range"]::-moz-range-track {
    background: #f6234e;
    background-repeat: no-repeat;
    background-size: cover;
    height: 1rem;
}

.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f6234e;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #27ff97;
}

input:focus + .slider {
    box-shadow: 0 0 1px #27ff97;
}

input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/*.fakeCircle {
    position: absolute;
    z-index: 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    background-color: #dadce8;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}
.fakeCircle:after {
    position: absolute;
    z-index: 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    background-color: #F9F9F9;
    width: 80%;
    height: 80%;
    content: "";
    top:10%;
    left:10%;
}*/

.slick-dots {
    position: relative;
    top: 2px;
    z-index: -999;
}

.suggestions-content {
    z-index: 999999;
    position: relative;
    background: white;
    max-height: 360px;
    overflow-y: auto;
}

.container {
    margin: 0 auto;
    z-index: 9999;
    position: relative;
}

body .react-confirm-alert-blur {
    filter: unset !important;
    -webkit-filter: unset !important;
}

.dark #react-confirm-alert > .react-confirm-alert-overlay {
    background: rgba(30, 30, 30, 0.95) !important;
}

.dark .react-confirm-alert-body {
    background: #000000;
}

.dark .react-confirm-alert-body > h1 {
    color: #ffffff;
    opacity: 0.8;
}

.loginForm input:-webkit-autofill,
.loginForm input:-webkit-autofill:hover,
.loginForm input:-webkit-autofill:focus,
.loginForm input:-webkit-autofill:active,
.resetForm input:-webkit-autofill,
.resetForm input:-webkit-autofill:hover,
.resetForm input:-webkit-autofill:focus,
.resetForm input:-webkit-autofill:active {
    border-radius: 50px;
    -webkit-box-shadow: transparent !important;
    background: transparent !important;
}

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark input:-webkit-autofill:active {
    border-radius: 50px;
    -webkit-box-shadow: transparent !important;
}

/* .dark input:-webkit-autofill {
    -webkit-text-fill-color: #ffffff !important;
} */

.react-toggle-track {
    background: transparent !important;
    border: 1px solid #27ff97 !important;
}

.react-toggle-thumb {
    background: #27ff97 !important;
    border: 1px solid #27ff97 !important;
}

.customCheckbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.customCheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #27FF97;
}

/* On mouse-over, add a grey background color */
.customCheckbox:hover input ~ .checkmark {
    background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.customCheckbox input:checked ~ .checkmark {
    border: 1px solid #27FF97;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.customCheckbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.customCheckbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #27FF97;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*
css incase required same changes for retina display
@media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation:landscape)
  and (-webkit-min-device-pixel-ratio: 2) {
  .slick-slide > div > div {
      height:560px!important;
      overflow:hidden;
  }
  .slick-slide:first-child > div > div > div > div{
        position:relative;
        margin-top:0px;
        margin-bottom:0px;
  }
  .slick-slide > div > div > div > div{
        position:relative;
        margin-top:30px;
        margin-bottom:20px;
  }
}*/

.build_slider .slick-prev,
.build_slider .slick-next {
    top: auto;
    transform: translate(0, 0);
    bottom: -40px;
    height: 40px;
    width: 40px;
    right: 0;
    margin: 0 auto;
    left: 0;
}

.build_slider .slick-prev::before,
.build_slider .slick-next::before {
    content: "";
    width: 40px;
    height: 40px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
}

.build_slider .slick-prev::before {
    background-image: url(../../public/images/left_arrow.svg);
}

.build_slider .slick-next::before {
    background-image: url(../../public/images/right_arrow.svg);
}

.build_slider .slick-prev {
    transform: translateX(-100px);
}

.build_slider .slick-next {
    transform: translateX(100px);
}

.line-clamp-3 {
    overflow: hidden;
    height: 47px;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.line-clamp-2 {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.build_slider .slick-track {
    display: flex;
    flex-wrap: wrap;
}

.build_slider .slick-slide {
    float: none;
    height: auto;
}

.build_slider .slick-slide > div {
    height: 100%;
}

.socialCopyTrading .slick-list, .socialCopyTrading .slick-track {
    height: 100%;
}

.channel-breakouts-swiper .slick-track {
    margin-left: 0;
}

@media screen and (min-width: 810px) {
    .next-prev-button-custom {
        width: 100%;
        max-width: 810px !important;
        margin: 0 auto;
    }

    .card-slider .slick-prev {
        left: 92% !important;
    }
}

@media screen and (min-width: 992px) {
    .next-prev-button-custom {
        width: 100%;
        max-width: 992px !important;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1024px) {
    .next-prev-button-custom {
        width: 100%;
        max-width: 1024px !important;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1200px) {
    .next-prev-button-custom {
        width: 100%;
        max-width: 1200px !important;
        margin: 0 auto;
    }

    .card-slider .slick-prev {
        left: 95% !important;
    }
}

@media screen and (min-width: 1536px) {
    .next-prev-button-custom {
        width: 100%;
        max-width: 1535px !important;
        margin: 0 auto;
    }
}

@media screen and (max-width: 991px) {
    .build_slider .slick-next {
        transform: translateX(70px);
    }

    .build_slider .slick-prev {
        transform: translateX(-70px);
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .slick-slider:not(.build_slider, .card-slider) .slick-slide > div > div {
        height: 560px !important;
        overflow: hidden;
    }

    .slick-slider:not(.build_slider, .card-slider)
    .slick-slide:first-child
    > div
    > div
    > div
    > div {
        position: relative;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .slick-slider:not(.build_slider, .card-slider) .slick-slide > div > div > div > div {
        position: relative;
        margin-top: 30px;
        margin-bottom: 20px;
    }
}

.expert-trader .semicircle-container > svg > circle:nth-child(2) {
    stroke: #F89412 !important;
}

.expert-trader .semicircle-container > svg > circle:nth-child(1) {
    stroke: #404040 !important;
}

.expert-trader .dark .semicircle-percent-value {
    color: #F89412 !important;
}

.backtest-report .semicircle-percent-value {
    bottom: 20px !important;
}

.backtest-report .semicircle-container svg {
    margin: 0 auto;
}

.success-backtest-report .semicircle-percent-value {
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(to bottom, #00FF9B, #00B8B5);
    color: transparent;
    font-size: 24px;
}

.warning-backtest-report .semicircle-percent-value {
    color: #EE2B50;
}

.list-decimal {
    list-style-type: decimal;
    padding-inline-start: 32px;
}

ol[class^="list-"] > li {
    padding-inline-start: 10px;
}

.test-page-slider .slick-slider:not(.build_slider) .slick-slide > div > div {
    height: inherit !important;
}

/* start subscription page input range*/
.subscription-input-range .input-range__label {
    display: none;
}

.subscription-input-range .input-range__slider {
    background: linear-gradient(90.9deg, #27FF97 1.92%, #23B0B5 100%);
    border: 1px solid #00dfab;
    height: 25px;
    width: 25px;
    position: relative;
    top: -10px;
}

.subscription-input-range .input-range__track {
    height: 0.8rem;
}

.subscription-input-range .input-range__track {
    background: #313131
}

.subscription-input-range .input-range__track--active {
    background: linear-gradient(90.9deg, #27FF97 1.92%, #23B0B5 100%);;
}

/* end subscription page input range*/
/*start iframe css for build page*/
.build-page #chart_widget iframe {
    border-radius: 8px;
}

/*end iframe css for build page*/

.subscription-box-shadow {
    box-shadow: 0px 0px 22.0719px #b7003b;
}

text.highcharts-credits {
    display: none !important;
}


/*data table*/
.data-table .rdt_Table *, .data-table .rdt_Pagination {
    background: #161616;
    /* color: white;  */
    font-size: 16px;
    font-family: "shoika-retinaregular";
}

.data-table .rdt_TableHeadRow * {
    @apply text-transparent bg-clip-text bg-gradient-to-r from-newtextGradientStart to-newtextGradientEnd;
}

/* data table*/

.popup-content {
    /* top: 75px !important; */
    background: transparent!important;
    z-index:9999!important;
    border: none !important;
}
.popup-content > div:first-child {
    display: none;
}

.blink {
    animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.card-slider .slick-arrow {
    top: -22px;
    position: absolute;
}

.card-slider .slick-prev {
    left: 85%;
}

.card-slider .slick-next {
    right: 2px;
}

.card-slider .slick-track {
    height: fit-content;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #F89412 #000;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #000;
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #F89412;
    border-radius: 5px;
    border: 1px solid #000;
  }


/* react-select-css*/
.custom-select *[class^=filter__] {
    box-shadow: none!important;
    z-index: 999!important;
}
/* react-select-css*/
.otpInput input {
    border-color: white !important;
}
/* Make QuillJS editor height 100% */
.ql-container {
    min-height: 10rem;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
} 
.reset-style {
    all: unset;
}
#tidio-chat {
    z-index: 10;
    position: absolute;
}
/*intercom style*/
/* .intercom-lightweight-app-launcher.intercom-launcher, .intercom-12og57v, .intercom-zi3hra {
    background-color: #00cdad!important;
} */